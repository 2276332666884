import {
  string
} from 'yup';
import 'yup-phone';

import './isCorporateRule';

import MESSAGES from './errorMessages';

const getRules = (locale = 'EN') => ({
  required: string().required(MESSAGES[locale].required),
  email: string()
    .email(MESSAGES[locale].email)
    .required(MESSAGES[locale].required),
  corporateEmail: string()
    .email(MESSAGES[locale].email)
    .isCorporate(MESSAGES[locale].corporateEmail)
    .required(MESSAGES[locale].required),
  phone: string()
    .phone('', false, MESSAGES[locale].phone)
    .required(MESSAGES[locale].required),
  company: string()
    .required(MESSAGES[locale].company),
});

export default getRules;
