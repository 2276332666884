import {
  addMethod, mixed
} from 'yup';

import { getFreeDomains } from 'api/freeDomains';

const domainsPromise = getFreeDomains()
  .then(({ data }) => data.split(',\n'))
  .catch(() => []);

export default addMethod(mixed, 'isCorporate', function (message) {
  return this.test({
    name: 'isCorporate',
    message: message,
    test(value) {
      if (!value || !value.trim()) return;

      return domainsPromise
        .then(domains => {
          const emailDomain = value.split('@')[1];

          return !domains.includes(emailDomain);
        })
    }
  });
});