import React, { useMemo, useState, useCallback } from "react";
import { useField } from "formik";
import classnames from "classnames";

import * as styles from "./input.module.scss";

const Input = ({
  className,
  label,
  requiredField = false,
  isDisabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const statusCssClasses = useMemo(
    () => ({
      [styles.error]: meta.touched && meta.error,
      [styles.success]: meta.touched && !meta.error,
      [styles.focused]: isFocused,
      [styles.disabled]: isDisabled,
    }),
    [meta, isFocused, isDisabled]
  );

  const focusHandler = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const blurHandler = useCallback(
    (e) => {
      setIsFocused(false);
      field.onBlur(e);
    },
    [setIsFocused, field]
  );

  return (
    <div className={classnames(styles.input, className, statusCssClasses)}>
      <div>
        {label && (
          <label
            htmlFor={`input-${props.name}`}
            className={classnames(
              styles.input_label,
              "scr-txt-up",
              "scr-small"
            )}
          >
            {label} {requiredField && <span className="scr-red">*</span>}
          </label>
        )}

        <input
          id={`input-${props.name}`}
          className={classnames("scr-reg", styles.input_elem)}
          {...field}
          {...props}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
      </div>

      {meta.touched && meta.error && (
        <div className={classnames("scr-small", "scr-red", styles.input_error)}>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Input;
