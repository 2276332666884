const MESSAGES = {
  en: {
    required: 'Required',
    email: 'Invalid email address',
    phone: 'Invalid phone number',
    corporateEmail: 'Enter your corporate email',
    company: 'Enter your company name'
  },
  de: {
    required: 'Erforderlich',
    email: 'Ungültige E-Mail Adresse',
    phone: 'Ungültige Telefonnummer',
    corporateEmail: 'Geben Sie Ihre Firmen-E-Mail ein',
    company: 'Geben Sie Ihren Firmennamen ein'
  },
};

export default MESSAGES;
